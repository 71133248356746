import React, { ReactNode } from "react";
import styled from "styled-components";

const StyledHeading1 = styled.h1<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: normal;
  margin: 0 0 8px;
`;

const Heading1 = ({
  color,
  children,
}: {
  color: string;
  children: ReactNode;
}) => {
  return <StyledHeading1 color={color}>{children}</StyledHeading1>;
};

const StyledHeading2 = styled.h2<{ color: string; fontSize?: string }>`
  color: ${({ color }) => color};
  font-size: ${(props) => props.fontSize || "0.875rem"};
  font-weight: normal;
  margin: 0 0 0.375rem;
  text-align: left;
`;

const Heading2 = ({
  color,
  fontSize,
  children,
}: {
  color: string;
  fontSize?: string;
  children: ReactNode;
}) => {
  return (
    <StyledHeading2 color={color} fontSize={fontSize}>
      {children}
    </StyledHeading2>
  );
};

const StyledParagraph = styled.p<{ color: string; fontSize?: string }>`
  color: ${({ color }) => color};
  font-family: "SelfModernRegular";
  font-size: ${(props) => props.fontSize || "1.5rem"};
  font-weight: 400;
  margin: 0 0 1.25rem;
  text-align: left;
`;

const Paragraph = ({
  color,
  fontSize,
  children,
}: {
  color: string;
  fontSize?: string;
  children: ReactNode;
}) => {
  return (
    <StyledParagraph color={color} fontSize={fontSize}>
      {children}
    </StyledParagraph>
  );
};

export { Heading1, Heading2, Paragraph };
