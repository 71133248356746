/* About Me - navy & pink */
export const ABOUT_ME_THEME = {
  FONT_MAIN: "#FFDFF8",
  FONT_SECONDARY: "#90869F",
  BACKGROUND: "#212D46",
};

/* Pics - gray & red */
export const PICS_THEME = {
  FONT_MAIN: "#ACD5F3",
  FONT_SECONDARY: "#647988",
  BACKGROUND: "#1C1C1C",
};

/* Writing -  */

/* Colophon - green & yellow */
export const COLOPHON_THEME = {
  FONT_MAIN: "#FFFBCE",
  FONT_SECONDARY: "#A2A483",
  BACKGROUND: "#495340",
};

/* Error 404 page - brown & blue  */
export const ERROR_THEME = {
  FONT_MAIN: "#D6F5E0",
  FONT_SECONDARY: "#8A938B",
  BACKGROUND: "#2B2727",
};

export const bgColourFromUrl = (url: string) => {
  if (url === "/") {
    /* navy & pink */
    return ABOUT_ME_THEME.BACKGROUND;
  } else if (url === "/pics") {
    /* gray and red */
    return PICS_THEME.BACKGROUND;
    // } else if (url === "/writing") {
    //   //   /* TBC! */
    //   //   document.documentElement.style.setProperty("--background", "#FFFFFF");
    // } else if (url === "/colophon") {
    /* green & yellow */
    // return COLOPHON_THEME.BACKGROUND;
  } else {
    /* error page */
    return ERROR_THEME.BACKGROUND;
  }
};

export const setPageColours = (url: string) => {
  if (url === "/") {
    /* navy & pink */
    document.documentElement.style.setProperty(
      "--font-main",
      ABOUT_ME_THEME.FONT_MAIN
    );
    document.documentElement.style.setProperty(
      "--font-secondary",
      ABOUT_ME_THEME.FONT_SECONDARY
    );
    document.documentElement.style.setProperty(
      "--background",
      ABOUT_ME_THEME.BACKGROUND
    );
    return ABOUT_ME_THEME.FONT_MAIN;
  } else if (url === "/pics") {
    /* gray and red */
    document.documentElement.style.setProperty(
      "--font-main",
      PICS_THEME.FONT_MAIN
    );
    document.documentElement.style.setProperty(
      "--font-secondary",
      PICS_THEME.FONT_SECONDARY
    );
    document.documentElement.style.setProperty(
      "--background",
      PICS_THEME.BACKGROUND
    );
    return PICS_THEME.FONT_MAIN;
    // } else if (url === "/writing") {
    //   //   /* TBC! */
    //   //   document.documentElement.style.setProperty("--font-main", "#000000");
    //   //   document.documentElement.style.setProperty("--font-secondary", "#555555");
    //   //   document.documentElement.style.setProperty("--background", "#FFFFFF");
    // } else if (url === "/colophon") {
    //   /* green & yellow */
    //   document.documentElement.style.setProperty(
    //     "--font-main",
    //     COLOPHON_THEME.FONT_MAIN
    //   );
    //   document.documentElement.style.setProperty(
    //     "--font-secondary",
    //     COLOPHON_THEME.FONT_SECONDARY
    //   );
    //   document.documentElement.style.setProperty(
    //     "--background",
    //     COLOPHON_THEME.BACKGROUND
    //   );
    //   return COLOPHON_THEME.FONT_MAIN;
  } else {
    /* error page */
    document.documentElement.style.setProperty(
      "--font-main",
      ERROR_THEME.FONT_MAIN
    );
    document.documentElement.style.setProperty(
      "--font-secondary",
      ERROR_THEME.FONT_SECONDARY
    );
    document.documentElement.style.setProperty(
      "--background",
      ERROR_THEME.BACKGROUND
    );
    return ERROR_THEME.FONT_MAIN;
  }
};
