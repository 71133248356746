import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { usePresence, motion } from "framer-motion";

import { setPageColours } from "../constants";
import Photo from "../components/Photo";
import { Heading2, Paragraph } from "../components/Headings";
import { timestampToString } from "../utils/timestampToString";

type PictureData = {
  src: string;
  location: string;
  date: string;
  isPortrait: boolean;
};

const StyledPics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #picsText {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    gap: var(--gap-width);

    .picsTextContainer {
      flex: 1;
    }
  }

  #picsGrid {
    width: 100%;
    max-width: 1400px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.75rem 1.2rem;
  }

  @media screen and (max-width: 960px) {
    #picsGrid {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.75rem 1.5rem;
    }
  }

  @media screen and (max-width: 600px) {
    #picsText {
      flex-direction: column;
      gap: 0;
    }

    #picsGrid {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.75rem 1.5rem;
    }
  }
`;

const Pics = () => {
  const location = useLocation();
  const [isPresent, safeToRemove] = usePresence();
  const [pictures, setPictures] = useState<PictureData[]>([]);

  useEffect(() => {
    if (isPresent) {
      setPageColours(location.pathname);
    } else {
      safeToRemove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  useMemo(() => {
    fetch("/.netlify/functions/pictures")
      .then((res) => res.json())
      .then((data) => {
        let tempPhotos: PictureData[] = [];
        data.forEach((entry: any) => {
          let photoEntry: PictureData = {
            src: "",
            location: "",
            date: "",
            isPortrait: true,
          };
          photoEntry.src = entry.fields.image.fields.file.url;
          photoEntry.location = entry.fields.location;
          photoEntry.date = timestampToString(entry.fields.date);
          photoEntry.isPortrait = entry.fields.isPortrait;
          tempPhotos.push(photoEntry);
        });
        setPictures(tempPhotos);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <StyledPics>
      <div id="picsText">
        <div className="picsTextContainer" />
        <div className="picsTextContainer">
          <Heading2 color="var(--font-secondary)">Shot on</Heading2>
          <Paragraph color="var(--font-main)">
            Olympus Mju Zoom 105 using Kodak Gold/Ultramax
          </Paragraph>
        </div>
      </div>
      <motion.div
        id="picsGrid"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            staggerChildren: 1,
          },
        }}
      >
        {pictures.map((picture: PictureData, idx: number) => {
          return <Photo {...picture} idx={idx + 1} />;
        })}
      </motion.div>
    </StyledPics>
  );
};

export default Pics;
