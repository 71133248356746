import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { usePresence } from "framer-motion";

import transition from "../transition";
import { setPageColours } from "../constants";

const StyledPageNotFound = styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  h1 {
    color: var(--font-main);
    font-size: 6rem;
    font-weight: bold;
    margin: 1rem 0 8px 0;
  }

  p {
    color: var(--font-main);
    font-size: 1rem;
    font-weight: normal;
    margin: 0 0 16px;
  }

  #backButton {
    width: 96px;
    height: 32px;
    background-color: #777d77;
    transition: all 0.4s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.42, 1);
    border: 0px solid black;
    border-radius: 4px;
    margin: 1rem 0;
  }

  #backButtonText {
    color: var(--font-main);
    opacity: 0.5;
    font-size: 14px;
  }

  #backButton:hover {
    cursor: pointer;
    opacity: 1;
    background-color: #51524f;
  }
`;

const PageNotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPresent, safeToRemove] = usePresence();

  const navigateHome = () => {
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (isPresent) {
      setPageColours(location.pathname);
    } else {
      safeToRemove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <StyledPageNotFound id="404PageContent">
      <h1>Uh oh</h1>
      <p>I couldn't find that page for you - try double checking that URL?</p>
      <button id="backButton" onClick={navigateHome}>
        <span id="backButtonText">Back home</span>
      </button>
    </StyledPageNotFound>
  );
};

export default transition(PageNotFound);
