import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Octokit } from "@octokit/core";
import { Link } from "react-router-dom";

const timestampToString = (timestamp: string) => {
  return new Date(timestamp).toLocaleString("en-AU", {
    dateStyle: "medium",
  });
};

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-width);
  width: 100%;
  margin-bottom: 48px;

  a {
    text-decoration: none;
  }

  p {
    color: var(--font-main);
    font-size: 0.875rem;
    text-align: left;
    margin: 0 0 8px;
  }

  #footerColumn1,
  #footerColumn2 {
    flex: 1;
  }

  #footerColumn3 {
    margin-right: 24px; /* To align this column to the centre of the grid */
    flex: 2;

    p {
      color: var(--font-secondary);
    }
  }

  @media screen and (max-width: 960px) {
    #footerColumn1 {
      display: none;
    }

    #footerColumn2 {
      flex: 2;
    }
  }
`;

const Footer = () => {
  const [lastUpdated, setLastUpdated] = useState("");

  const fetchLastUpdatedTime = async () => {
    if (
      !process.env.REACT_APP_GITHUB_ACCESS_TOKEN ||
      !process.env.REACT_APP_GITHUB_OWNER ||
      !process.env.REACT_APP_GITHUB_REPO
    ) {
      return;
    }

    const octokit = new Octokit({
      auth: process.env.REACT_APP_GITHUB_ACCESS_TOKEN,
    });

    const res = await octokit.request(
      "GET /repos/{owner}/{repo}/branches/{branch}",
      {
        owner: process.env.REACT_APP_GITHUB_OWNER,
        repo: process.env.REACT_APP_GITHUB_REPO,
        branch: "main",
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      }
    );

    const time = timestampToString(
      res?.data?.commit?.commit?.committer?.date ?? ""
    );
    setLastUpdated(time);
  };

  useEffect(() => {
    fetchLastUpdatedTime();
  }, [lastUpdated]);

  return (
    <StyledFooter>
      <div id="footerColumn1" />
      <div id="footerColumn2">
        <Link to="mailto:rexana.jiang@gmail.com" className="link">
          <p>Email</p>
        </Link>
        {/* <Link to="/colophon" className="link">
          <p>Colophon</p>
        </Link> */}
      </div>
      <div id="footerColumn3">
        <p>This site was last updated on {lastUpdated}.</p>
      </div>
    </StyledFooter>
  );
};

export default Footer;
