import React from "react";
import styled from "styled-components";
import { AsyncImage } from "loadable-image";

const StyledPhoto = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  #pictureContainer {
    width: 100%;
    height: 100%;
  }

  #picture {
    height: fit-content;
    width: 100%;
    object-fit: contain;
    border: 3px solid white;
    margin-bottom: 0.25rem;
  }

  span {
    font-size: 0.875rem;
  }

  #pictureText {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
  }

  #pictureIndex {
    color: var(--font-main);
    text-align: left;
  }

  #pictureCaption,
  #pictureDate {
    width: 100%;
    color: var(--font-secondary);
    text-align: right;
  }

  @media screen and (max-width: 960px) {
    span {
      font-size: 0.75rem;
    }
  }
`;

const Photo = ({
  src,
  idx,
  location,
  date,
  isPortrait,
}: {
  src: any;
  idx: number;
  location: string;
  date: string;
  isPortrait: boolean;
}) => {
  return (
    <StyledPhoto>
      <AsyncImage
        id="picture"
        src={src}
        style={{
          width: "100%",
          height: "auto",
          aspectRatio: isPortrait ? 120 / 181 : 181 / 120,
        }}
        loader={
          <div
            style={{
              width: "100%",
              height: "auto",
              aspectRatio: isPortrait ? 120 / 181 : 181 / 120,
            }}
          />
        }
      />
      <div id="pictureText">
        <span id="pictureIndex">#{idx}</span>
        <span id="pictureCaption">{location}</span>
      </div>
      <span id="pictureDate">{date}</span>
    </StyledPhoto>
  );
};

export default Photo;
