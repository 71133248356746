import { motion } from "framer-motion";

export const variants = {
  in: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
  out: {
    opacity: 0,
    transition: { duration: 0.5 },
  },
};

// Helper function that wraps each page component in a Framer motion page transition
const Transition = (Component: () => JSX.Element) => {
  return () => (
    <motion.article
      initial="out"
      animate="in"
      exit="out"
      variants={variants}
      style={{ width: "100%" }}
    >
      <Component />
    </motion.article>
  );
};

export default Transition;
