import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { usePresence } from "framer-motion";

import me from "../assets/img/me.jpg";
import { Heading2, Paragraph } from "../components/Headings";
// import Collapsible from "../components/Collapsible";
import transition from "../transition";
import { setPageColours } from "../constants";

const StyledAboutMe = styled.article`
  display: flex;
  flex-direction: row;
  gap: var(--gap-width);

  div {
    flex: 1;
  }

  #picture {
    width: 75%;
    -webkit-filter: brightness(97%) grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: brightness(97%) grayscale(100%);
    transition: filter 0.2s ease-out;
  }

  #picture:hover {
    -webkit-filter: none;
    filter: none;
  }

  #textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #textContainer p {
    display: block;
    max-width: 48rem;
  }

  .textSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;

    #picture {
      min-width: 55%;
      max-width: 70%;
      margin-bottom: 5rem;
    }

    #textContainer {
      p {
        max-width: 36rem;
      }
    }
  }
`;

const AboutMe = () => {
  const location = useLocation();
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    if (isPresent) {
      setPageColours(location.pathname);
    } else {
      safeToRemove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <StyledAboutMe id="aboutMePageContent">
      <div id="pictureContainer">
        <img
          id="picture"
          src={me}
          alt="Photobooth strip of me and my dog, Mango"
        />
      </div>
      <div id="textContainer">
        <div className="textSection">
          <Heading2 color="var(--font-secondary)">About me</Heading2>
          <Paragraph color="var(--font-main)">
            Hey - I'm Rexana! Welcome to my little corner of the internet :)
          </Paragraph>
          <Paragraph color="var(--font-main)">
            I’m a front-end developer based in Sydney, Australia with a keen
            interest in web design/interaction and product management. I love
            making cool stuff, whether that’s creating websites or getting my
            hands dirty with pottery. I'm also big on seeing new sights & scenes
            through travelling and immortalising these memories via digital &
            film photos.
          </Paragraph>
          <Paragraph color="var(--font-main)">
            Here on this site, I'll be putting up some pics I’ve taken here and
            away, occasionally writing stuff and, of course, obsessing over my
            dog Mango.
          </Paragraph>
        </div>

        <div className="textSection">
          <Heading2 color="var(--font-secondary)">Education</Heading2>
          <Paragraph color="var(--font-main)" fontSize="1.125rem">
            2020-23: B. Eng (Hons I) of Software Engineering, University of New
            South Wales
          </Paragraph>
        </div>

        {/* <Collapsible title="Why this site?">
          <Paragraph color="var(--font-main)" fontSize="1.125rem">
            Throughout the last few years I’ve been in search of a place to
            collate all my interests, be it design, engineering or just the
            hobbies I've picked up. So in typical engineer fashion, I decided to
            make one - read more about how I did this in the colophon.
          </Paragraph>
        </Collapsible> */}
      </div>
    </StyledAboutMe>
  );
};

export default transition(AboutMe);
