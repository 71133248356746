import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import "./App.css";
import Header from "./components/Header";
import AboutMe from "./pages/AboutMe";
import Pics from "./pages/Pics";
// import Writing from "./pages/Writing";
// import Colophon from "./pages/Colophon";
import Footer from "./components/Footer";
import PageNotFound from "./pages/404";
import { bgColourFromUrl } from "./constants";

function App() {
  const location = useLocation();

  return (
    <motion.div
      className="App"
      animate={{
        backgroundColor: bgColourFromUrl(location.pathname),
      }}
      transition={{ duration: 0.5, delay: 0.5 }}
      initial={false}
    >
      <Header />
      <div id="pageContent">
        <AnimatePresence mode="wait" initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route path="/">
              <Route path="/" index element={<AboutMe />} />
              <Route path="/pics" element={<Pics />} />
              {/* <Route path="/writing" element={<Writing />} /> */}
              {/* <Route path="/colophon" element={<Colophon />} /> */}
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </AnimatePresence>
      </div>
      <Footer />
    </motion.div>
  );
}

export default App;
